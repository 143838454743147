<template>
  <div>
    <h2 class="font-weight-bolder mb-2 hGoBack">
      <span @click="$router.go(-1)">
        <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
          <feather-icon icon="ArrowLeftCircleIcon" size="35" />
        </b-button>
      </span>
      Crear Mantenimiento
    </h2>
    <b-card>
      <!-- form -->
      <validation-observer ref="createMaintenance">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">
              <!-- name -->
              <validation-provider
                #default="{ errors }"
                :name="'Nombre'"
                rules="required"
              >
                <b-form-group :label="'Nombre*'" label-for="client-name">
                  <b-form-input
                    v-model="name"
                    name="name"
                    :placeholder="'Nombre'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <!-- client -->
              <validation-provider
                v-if="selectClients.length > 0"
                #default="{ errors }"
                :name="'Cliente'"
                rules="required"
              >
                <b-form-group :label="'Cliente*'" label-for="client">
                  <v-select
                    v-model="client"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectClients"
                    :placeholder="'Cliente'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="client && client.total_projects === 0"
                  class="text-danger"
                >
                  El cliente seleccionado no contiene ningun proyecto
                </small>
              </validation-provider>
              <!-- selectProjects -->
              <validation-provider
                v-if="selectProjects.length > 0"
                #default="{ errors }"
                :name="'project'"
                rules="required"
              >
                <b-form-group :label="'Obra'" label-for="project">
                  <v-select
                    ref="projects"
                    v-model="project"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectProjects"
                    :placeholder="'Seleccionar Obra'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <!-- date -->
              <b-form-group :label="'Fecha*'" label-for="date" required>
                <validation-provider
                  #default="{ errors }"
                  :name="'Fecha'"
                  rules="required"
                >
                  <flat-pickr
                    v-model="date"
                    name="date"
                    class="form-control"
                    :config="dateConfig"
                  />
                  <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- frequency -->
              <validation-provider
                v-if="frequencySelect.length > 0"
                #default="{ errors }"
                :name="'frequency_type'"
              >
                <b-form-group :label="'Frecuencia'" label-for="frequency_type">
                  <v-select
                    v-model="frequency_type"
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="frequencySelect"
                    :placeholder="'Frecuencia'"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <!-- responsable id -->
              <validation-provider #default="{ errors }" :name="'Responsable'">
                <b-form-group
                  :label="'Responsable de mantenimiento'"
                  label-for="project-user"
                >
                  <v-select
                    label="name"
                    :filterable="true"
                    :searchable="true"
                    :options="selectUsers"
                    :placeholder="'Responsable de mantenimiento'"
                    v-model="user"
                  />
                </b-form-group>
                <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-form-group
                v-if="listAssets.length > 0"
                label="Equipos disponibles en esta obra"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-checkbox-group
                  id="checkbox"
                  ref="assets"
                  v-model="assets"
                  :options="listAssets"
                  stacked
                  :aria-describedby="ariaDescribedby"
                  name="assets"
                  text-field="productNameWithIni"
                  value-field="id"
                >
                </b-form-checkbox-group>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <!-- description -->
              <b-form-group :label="'Descripción'" label-for="description">
                <quill-editor v-model="description" />
              </b-form-group>

              <b-form-group :label="'Documentos'" label-for="documents">
                <ImageDropzone ref="documents" :files="files" maxFiles="1" />
              </b-form-group>
            </b-col>

            <b-col cols="12 text-right">
              <b-button type="submit" variant="primary" class="mt-2 mr-1">
                {{ "Enviar" }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BLink,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormSelect,
} from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import ImageDropzone from "@/components/elements/ImageDropzone/ImageDropzone.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import { config } from "@/shared/app.config";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    ImageDropzone,
    BLink,
    quillEditor,
    BFormSelect,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      name: "",
      description: "",
      client: "",
      project: "",
      assets: [],
      date: "",
      files: [],
      required,
      frequency_type: "",
      user: "",
      frequencyText: config.frequencyText,
      frequencySelect: config.frequencySelect,
      dateConfig: {
        enableTime: true,
        time_24hr: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getUser",
      selectClients: "clients/getSelectClients",
      selectProjects: "projects/getItems",
      selectAssets: "assets/getItems",
      selectUsers: "users/getSelectUsers",
    }),
    listAssets () {
      const filteredProducts = this.selectAssets.filter((asset) => {
        const categoryName = asset.category.name.toLowerCase();
        return categoryName.includes('cortina') || categoryName.includes('cortinas');
      });
      return filteredProducts
    }
  },
  watch: {
    async client() {
      if (this.client !== null) {
        this.project = "";
        await this.getListProjects({
          client: this.client.id,
          page: 1,
          per_page: 9999,
        });
      } else {
        this.$refs.projects.options.splice(
          0,
          this.$refs.projects.options.length
        );
      }

      //   this.selectProjects = []
      // await this.getListProjects({ client: this.client.id, page: 1, per_page: 9999 })
    },
    async project() {
      if (this.project !== null) {
        this.getListAssets({
          id_client: this.client.id,
          project_id: this.project.id,
          page: 1,
          per_page: 9999,
        });
      } else {
        this.$refs.assets.options.splice(0, this.$refs.assets.options.length);
      }
    },
  },
  methods: {
    ...mapActions({
      create: "maintenances/create",
      getSelectClients: "clients/selectClients",
      getListProjects: "projects/getListProjects",
      getListAssets: "assets/getListAssets",
      searchUsers: "users/selectUsers",
    }),
    async loadInputs() {
      if (this.currentUser.clients.length > 0) {
        this.client = this.currentUser.clients[0];
      } else {
        this.getSelectClients();
      }

      if (this.$route.params.client_id) {
        this.selectClients.filter((item) => {
          if (item.id == this.$route.params.client_id) {
            this.client = item;
          }
        });

        await this.getListProjects({
          client: this.client.id,
          page: 1,
          per_page: 9999,
        });

        this.selectProjects.filter((item) => {
          if (item.id == this.$route.params.project_id) {
            this.project = item;
          }
        });

        await this.getListAssets({
          id_client: this.client.id,
          project_id: this.project.id,
          page: 1,
          per_page: 9999,
        });

        this.assets = this.$route.params.asset.id;
      }
    },
    handleSubmit() {
      this.$refs.createMaintenance.validate().then((success) => {
        if (success) {
          const formData = this.createFormData();
          if (formData) {
            this.create({ maintenance: formData });
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
          block: 'end', 
          });
        }
      });
    },
    createFormData() {
      const data = this.$refs.documents.getFormData("documents");

      let user = this.user ? this.user.id : "";

      data.append("name", this.name);
      data.append("user_id", user);

      if (this.description !== "" || this.description !== null) {
        data.append("description", this.description);
      }

      if (this.date) {
        data.append("date", FlatPickrToTimestamp(this.date));
      }

      if (this.project) {
        data.append("project_id", this.project.id);
      }

      if (this.frequency_type) {
        data.append("frequency_type", this.frequency_type.value);
      }

      this.assets.forEach((element) => data.append("assets[]", element));

      // return false
      return data;
    },
  },
  async created() {    
    this.$store.commit('assets/setItems', [])
    this.$store.commit('projects/setItems', [])
    await this.loadInputs();
    
    await this.searchUsers({
      page: 1,
      per_page: 999,
      search: "",
      roles: ["tec"],
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
